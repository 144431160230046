@import "../../utils/includes.scss";

//
// Fonts
//

$fontFamily: 'ProximaNova-Regular', Arial;
$boldFontFamily: 'ProximaNova-Semibold', Arial;
$jaguarFontFamily:'JaguarModernWeb-Regular', Arial;
$jaguarBoldFontFamily:'JaguarModernWeb-Bold', Arial;
$boldFontWeight: normal;
$titleFontFamily: $jaguarBoldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

$fontSize-2: 25px;
$fontSize-1: 30px;

$fontSizeLineHeight-2: 36px;
$fontSizeLineHeight-1: 42px;
//
// Colors
//

$textColor: #000000;
$linkColor: #3C3C3B;
$labelColor: #999999;
$formColor: #000000;
$utilColor: #999999;
$priceColor: #3C3C3B;
$lightColor: #999999;
$btnColorAlpha: #000000;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #000000;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #3C3C3B;
$warningColor: #3C3C3B;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: transparent;

$bgBtnAlpha: #A7A9AC;
$colorBtnAlpha: #fff;
$bgBtnBeta: #3C3C3B;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #474747;
$colorBtnOutlineBeta: #7E7E7E;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #f0710a;

$garageBackground: #ffffff;
$garageTextColor: #000000;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #474747;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #3C3C3B;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #00426b;
$progressBarCloseButtonBG: #3C3C3B;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #474747;
$paymentCalculatorClosedColor: #A7A9AC;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #474747;
$paymentCalculatorTermWrapperColor: #aaa;
$paymentCalculatorTermSelectedWrapperBG: #000000;
$paymentCalculatorTermSelectedColor: #fff;
$paymentCalculatorTermBG: #000000;
$paymentCalculatorTermColor: #EBEBEB;

$carTaglineColor : #ffffff;

$srButton1Background : #3b3b3c;
$srButton1Color : #ffffff;
$srButton1HoverBackground : #444444;
$srButton1HoverColor : #ffffff;

$srButton2Background : #ffffff;
$srButton2Color : #3b3b3c;
$srButton2BorderColor : #3b3b3c;
$srButton2HoverBackground : #3b3b3c;
$srButton2HoverColor : #ffffff;

// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__jaguar{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__jaguar{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        body{
            font-family: $fontFamily;
        }
        .sr-button-1{
            color: $srButton1Color !important;
            background: $srButton1Background !important;
        }
        .sr-button-1:hover{
            color: $srButton1HoverColor !important;
            background: $srButton1HoverBackground !important;
        }
        .sr-button-2{
            border: 1px solid $srButton2BorderColor !important;
            background: $srButton2Background !important;
            color: $srButton2Color !important;
        }
        .sr-button-2:hover{
            background: $srButton2HoverBackground !important;
            color: $srButton2HoverColor !important;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #3b3b3c;
            }
        }
        .sr-link{
            color: $srButton2Color !important;
        }
        .sr-text,.sr-text-regular, .sr-text-thin {
            font-family: $fontFamily;
        }
        .sr-title-1, .sr-title-2 {
            font-family: $jaguarBoldFontFamily!important;
        }
        .sr-subtitle-1, .sr-subtitle-2{
            font-family: $boldFontFamily !important;
        }
        .is-price{
            font-family: $jaguarBoldFontFamily !important;
        }
        .payment-row.disclaimer.is-light.sr-text{
            width: 55%;
        }
        .wysiwyg p{
            font-size: 14px;
            line-height: 18px;
        }
        &.Checkout{
            background-color: $bgSecondaryColor;
        }
        .InputText.styled-placeholder{
            height: 50px;
        }
        .vdp-used-form .TextArea{
            height: 180px;
        }
        .vehicle-overview__title{
            margin: 0 0 50px;
        }
        .listing-used .listing-used-inner-content .listing-used-header-refine button,
        .listing-used-payment .listing-used-set-budget button,
        .listing-used .listing-used-inner-content .listing-used-header-refine button::after,
        .listing-used-payment .listing-used-set-budget button::after{
            color: #EBEBEB;
        }
        .section-catalog-filter {
            .catalog-filter-type-name,
            .catalog-filter-type-all{
                color: #000000;
            }
        }
        .message-handler.warning{
            background-color: $warningColor;
        }
        .listing-used-filters .rc-slider .rc-slider-handle{
            border: 2px solid $successColor;
        }
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button.selected{
            border: 2px solid $successColor;
            color: $successColor;
        }
        .vehicle-tile-rebate-single{
            color: #000000;
        }
        .vehicle-item {
            .vehicle-name{
                font-family: $jaguarBoldFontFamily;
                color: #000000;
                font-size: 14px;
                line-height: 19px;
            }
            .vehicle-cost,
            .vehicle-tile-rebate-single,
            .vehicle-payment-title,
            .vehicle-price,
            .vehicle-price-label{
                color: #000000;
            }
            .vehicle-payment-rate,
            .vehicle-payment-cashdown,
            .vehicle-payment-cost-credit{
                color: #586167;
            }
            .taxes-detail{
                color: #717171;
            }
            .vehicle-payment-amount{
                font-size: 20px;
                line-height: 25px;
            }
        }
        .VehicleDisclaimersContent{
            color: #717171;
        }
        .method-button-payment-bar{
            button{
                color: #000000;
            }
        }
        .listing-used-filters-head{
            div{
                color: #000000;
            }
        }
        .listing-used-filters-bloc{
            div{
                color: #000000;
            }
        }
        .listing-tiles{
            .car-name{
                font-family: $jaguarFontFamily;
            }
            .listing-tile-link .listing-tile-note{
                background: #ffffff;
            }
        }
        .listing-used,
        .listing-tile-note .vehicle-note{
            background-color: transparent;
        }
        .listing-tile .listing-tile-image .car-tagline {
            color: $carTaglineColor;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected .price span{
            color: #ffffff !important;
        }
        .TradeInTile--content-estimate{
            background-color: #000000 !important;
        }

        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #3b3b3c!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#3b3b3c 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #3b3b3c !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #3b3b3c !important;
            .icon,.icon-Crochet{
                color: #3b3b3c !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #3b3b3c;
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #3b3b3c !important;
            }
        }
        .doneLabel {
            color: #3b3b3c !important;
            .icon-Crochet{
                color: #3b3b3c !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #3b3b3c !important;
            }
        }
        .has-success-background{
            background-color: #3b3b3c !important;
        }

        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #3b3b3c!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#3b3b3c 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #3b3b3c !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #3b3b3c !important;
            .icon,.icon-Crochet{
                color: #3b3b3c !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #3b3b3c;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #3b3b3c;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #3b3b3c !important;
            }
        }
        .doneLabel {
            color: #3b3b3c !important;
            .icon-Crochet{
                color: #3b3b3c !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #3b3b3c !important;
            }
        }
        .has-success-background{
            background-color: #3b3b3c !important;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__jaguar{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}